import { render, staticRenderFns } from "./CourseTrainingPage.vue?vue&type=template&id=077083e6&scoped=true"
import script from "./CourseTrainingPage.vue?vue&type=script&lang=js"
export * from "./CourseTrainingPage.vue?vue&type=script&lang=js"
import style0 from "./CourseTrainingPage.vue?vue&type=style&index=0&id=077083e6&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "077083e6",
  null
  
)

export default component.exports