<template>
	<Page :loading="loading">
		<a href="/" class="go-back-link">
			<Icon type="arrowLeft" />
			{{ i18n('training', 'goBackLink') }}
		</a>
		<div class="progress-bar-container">
			<div class="progress-bar">
				<div class="progress" :style="{ width: ((progress || 0) * 100) + '%'}" />
			</div>
		</div>
		<!-- TODO: нажатие на любую из кнопок (AI, подсказка, етс) — для слов > 8 -->
		<!-- отменяет повторение, откладывая его ненадолго (на неделю, скажем) -->
		<!-- т.к. это именно те слова, которые должны уже всплывать сами -->
		<div class="course-is-empty" v-if="courseIsEmpty">
			{{ i18n('training', 'noWordsForTraining') }}
		</div>
		<div class="training-page learn-new-word" v-if="trainingPageMode === 'description'">
			<div class="card">
				<!-- <div class="translate-text">
					<b class="notranslate">{{ card.wordText }}</b>
					<span class="word-type-container">
						<PartOfSpeechBadge :content="card.wordPartOfSpeech" />
					</span> —
					<span v-if="card.wordTranslation && card.wordTranslation !== '-'">
						<span v-if="card.wordTranslation.includes(' | ')">
							<i>/{{ card.wordTranslation.split(' | ')[0] }}/</i>
							<span>{{ card.wordTranslation.split(' | ')[1] }}</span>
						</span>
						<span v-else>
							{{ card.wordTranslation }}
						</span>
					</span>
					<i v-else>{{ i18n('training', 'noTranslation') }}</i>
				</div> -->
				<div class="word-definition">
					<b class="notranslate">{{isEnglishWord && card.wordPartOfSpeech === 'verb' ? 'to ' : ''}}{{ card.wordText }}</b>
					<div class="word-type-container">
						<PartOfSpeechBadge :content="card.wordPartOfSpeech" size="big" />
					</div>
					<div class="word-definition-divider" />
					<div class="transcription" v-if="currentWordTranscription" @click="pronounceCurrentWord">{{currentWordTranscription}}</div>
					<Icon type="sound" @click="pronounceCurrentWord" />
				</div>
				<div class="word-translation">{{currentWordDefinition}}</div>
				<HanziWriter
					v-if="isChineseWord"
					:text="card.wordText"
					style="margin-top: 20px"
				/>
			</div>
			<div class="button-container">
				<!-- <ButtonProgress
					:progress="progress"
					design="action"
					size="big"
					@click="closeLearning"
				>
					{{ i18n('training', 'nextButtonLabel') }}
				</ButtonProgress> -->
				<Button
					design="action"
					size="big"
					@click="closeLearning"
					style="width: 200px"
				>{{ i18n('training', 'tryThisWordButtonLabel') }}</Button>

				<div style="display: block; margin-top: 12px"><Button
					design="link-button-primary"
					@click="iKnowIt"
					style="width: 200px"
				>{{ i18n('training', 'iKnowItButtonLabel') }}</Button></div>
			</div>
		</div>
	
		<div
			class="training-page"
			v-else-if="
				trainingPageMode === 'training' ||
				trainingPageMode === 'youglish' ||
				trainingPageMode === 'hanzi'
			"
		>
			<div class="card">
				<div class="buttons-panel">
					<!-- prevent default for not disappearing keyboard -->
					<a href="#" @click="help" @touchstart="help" @touchend="$event.preventDefault()">
					<Icon type="hint" />&nbsp;{{ i18n('training', 'helpButtonLabel') }}
					</a>
					<span style="position: relative">
						<a
							href="#"
							@click="explainPopupOpen = true; $event.stopPropagation()"
							:class="[explainPopupOpen && 'active', 'explain-link']"
							ref="explainLink"
						><Icon type="ai" :aiGradient="true" />&nbsp;{{ i18n('training', 'explainButtonLabel') }}</a>
						<transition name="fade">
							<Popup v-if="explainPopupOpen" :style="{
								width: explainPopupWidth + 'px',
								left: 0
							}">
								<Menu
									:options="explainOptions"
									@click="explanationType = $event; explainModalOpen = true; explainPopupOpen = false"
								/>
							</Popup>
						</transition>
					</span>
					<span style="position: relative">
						<span v-if="complained" style="font-size: 14px; margin-right: 15px">
							<Icon type="report" />&nbsp;{{ i18n('training', 'complaintSent') }}
						</span>
						<a
							v-else
							href="#"
							@click="complainMenuOpen = true; $event.stopPropagation()"
							@touchstart="complainMenuOpen = true; $event.stopPropagation()"
							@touchend="$event.preventDefault()"
						><Icon type="report" />{{ i18n('training', 'complainButtonLabel') }}</a>
						<transition name="fade">
							<Popup v-if="complainMenuOpen" :style="{
								width: complainPopupWidth + 'px',
								left: 0
							}">
								<Menu
									:options="reportOptions"
									@click="complain($event); complainMenuOpen = true;"
								/>
							</Popup>
						</transition>
					</span>
					<!-- <a
						href="#"
						@click="commentsOpen = true; $event.stopPropagation()"
						:class="commentsOpen && 'active'"
						ref="commentsLink"
					>{{ i18n('training', 'wordButtonLabel') }}</a> -->
					<!-- <a
						href="#"
						@click="pronounceCurrentSentence"
					>Sound over</a> -->

					<!-- <a
						href="#"
						@click="toggleYouglish(); $event.stopPropagation()"
						:class="youglishEnabled ? 'active' : 'overlined'"
					>{{ i18n('training', 'youglishButtonLabel') }}</a> -->
					<!-- <a href="#">{{
						card.memesCount === 0 ? 'Добавить мем' : `Мемы (${card.memesCount})`
					}}</a> -->
				</div>

				<!-- Training -->
				<div class="training-container v2-design columns">
					<!-- using v-show in order to avoid bug with rerendering youglish widget -->
					<div
						class="translate-text"
						v-show="trainingPageMode !== 'youglish'"
						:style="/* {
							opacity: $route.query.scenario === 'context-extreme'
								? 1 - card.wordRepeatTime / 20
								: 1 - card.wordRepeatTime / 10
						} */ {}"
					>
						{{ cardQuestion }}
					</div>
					<div :class="[
						'notranslate',
						'original-text',
						trainingPageMode === 'youglish' && 'youglish-original-text',
						textFieldType
					]">
						{{ trainingPageMode !== 'youglish' ? cardAnswerPre : '' }}
						<TextField
							:value="$store.state.courseTraining.answerToSend"
							@input="onTextInput"
							@keypress="$event.keyCode == 13 && next()"
							class="original-text-input"
							:style="{ width: wordLength + 'px' }"
							ref="textField"
							autocapitalize="off"
							:modifiers="cardAnswerLanguage === 'gr' ? ['poly-greek'] : []"
							:disabled="trainingPageMode === 'hanzi'"
							:design="trainingPageMode === 'hanzi' ? 'bottom-line' : 'default'"
							textFieldClass="training-text-field"
						/>
						{{ trainingPageMode !== 'youglish' ? cardAnswerPost : '' }}
					</div>
					<!-- <keep-alive>
						<YouglishWidget
							ref="youglishWidget"
							@captionConsumed="textFieldFocus"
							@error="toggleYouglish(false)"
							:style="trainingPageMode === 'youglish' ? {} : {display: 'none'}"
						/>
					</keep-alive> -->
					<HanziWriter
						v-show="trainingPageMode === 'hanzi'"
						ref="hanziWriter"
						style="margin-bottom: 20px"
						:key="$store.state.courseTraining.currentCardIndex"
						:text="cardAnswer"
						:quizMode="true"
						:quizLevel="card.wordRepeatTime <= 3 ? 0 : card.wordRepeatTime <= 6 ? 1 : 2"
						@quizCompleted="next({ ignoreCorrect: true })"
					/>
				</div>
			</div>
			<div class="button-container v2-design">
				<!-- <ButtonProgress
					:progress="progress"
					design="action"
					size="big"
					@click="next()"
				>
					{{ i18n('training', 'nextButtonLabel') }}
				</ButtonProgress> -->
				<Button
					v-if="textFieldType === 'wrong' && !correct"
					design="yellow"
					size="big"
					@click="help()"
					:disabled="$store.state.courseTraining.answerToSend === ''"
				>
					{{ i18n('training', 'giveMeAHint') }}
				</Button>
				<Button
					v-else
					design="action"
					size="big"
					@click="next()"
					:disabled="$store.state.courseTraining.answerToSend === ''"
				>
					{{ i18n('training', 'nextButtonLabel') }}
				</Button>
			</div>
		</div>

		<Modal
			:open="finishModalOpen"
		>
			<div class="finish-modal-content" v-if="userLoggedIn">
				<h3>
					{{ i18n('training', 'yayWeGot')(food[1]) }}
				</h3>
				<div class="image-container">
					<img :src="food[0]" ref="watermelon" :key="Math.random()" />
				</div>
				<div class="button-container">
					<Button
						design="action"
						@click="leave"
					>
						{{ i18n('training', 'feedTheCat') }}
					</Button>
					<Button
						design="white-button"
						@click="trainAgain"
					>
						{{ i18n('training', 'trainAgain') }}
					</Button>
				</div>
			</div>
			<div class="finish-modal-content unlogged" v-else>
				<h3>{{ i18n('training', 'unloggedLabel') }}</h3>
				<p>{{ i18n('training', 'unloggedDescription') }}</p>
				<div class="button-container">
					<Button
						design="google-auth"
						size="big"
						@click="authGoogle"
					>
						{{ i18n('login', 'authorizeGoogle') }}
					</Button>
				</div>
			</div>
		</Modal>

		<transition name="fade">
			<WordCommentsPopup
				v-if="commentsOpen"
				:wordId="wordId"
				:infoAbout="card.wordTranslation"
				:anchorElement="$refs.commentsLink"
			/>
		</transition>

		<ModalExplanation
			v-if="explainModalOpen"
			:explanationType="explanationType"
			:wordId="wordId"
			:sentenceId="sentenceId"
			:sentence="[cardAnswerPre, cardAnswer, cardAnswerPost]"
			:inputWord="$store.state.courseTraining.answerToSend"
			@close="explainModalOpen = false"
		/>
	</Page>
</template>

<script>
import watermelon from '@/assets/food/watermelon.svg';
import banana from '@/assets/food/banana.png';
import carrot from '@/assets/food/carrot.png';
import chicken from '@/assets/food/chicken.png';
import chili from '@/assets/food/chili.png';
import meat from '@/assets/food/meat.png';
import pizza from '@/assets/food/pizza.png';
import sushi from '@/assets/food/sushi.png';
import turkey from '@/assets/food/turkey.png';

import Button from '@/components/Button';
import ButtonProgress from '@/components/ButtonProgress';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import Page from '@/components/Page';
import PartOfSpeechBadge from '@/components/PartOfSpeechBadge';
import TextField from '@/components/TextField';
import YouglishWidget from '@/components/YouglishWidget';
import HanziWriter from '@/components/HanziWriter';
import Popup from '@/components/Popup';
import Menu from '@/components/Menu';

import WordCommentsPopup from '@/views/WordCommentsPopup';
import ModalExplanation from '@/views/training/ModalExplanation';

import { complain, addSecondsToLearning, addSecondsToLearningBeacon } from '@/lib/api';
import { getVoicesForLang, pronounce } from '@/lib/speech'; 
import polyGreekNormalize from '@/lib/poly-greek-normalize';
import { isChinese, convertChineseSentenceToPinyin } from '../lib/chinese';

function measureTextLength(text) {
	const div = document.createElement('div');
	div.textContent = text;

	// todo: font & font size
	div.style.fontFamily = 'Inter, Lato, Montserrat, Roboto';
	div.style.fontSize = '20px';
	div.style.position = 'absolute';
	div.style.top = '-10000px';
	div.style.left = '-10000px';
	document.body.appendChild(div);
	const s = getComputedStyle(div).width;
	document.body.removeChild(div);
	return parseInt(s);
}

export default {
	name: 'CourseTrainingPage',

	data: function() {
		const youglishEnabled = localStorage.getItem('lango-youglish-enabled');
		const i18n = this.i18n;
		return {
			textFieldType: null,
			finishModalOpen: false,
			showingNewWord: true,
			complainMenuOpen: false,
			commentsOpen: false,
			explainPopupOpen: false,
			explainModalOpen: false,
			youglishEnabled: youglishEnabled === 'false' ? false : true,

			startedTrainAt: null,

			explanationType: 'context',
			explainOptions: [{
				title: i18n('training', 'explanationContextTitle'),
				value: 'context',
				icon: {type: 'aiContext', aiGradient: true}
			}, {
				title: i18n('training', 'explanationOriginTitle'),
				value: 'origin',
				icon: {type: 'aiOrigin', aiGradient: true}
			}],

			reportOptions: [{
				title: i18n('training', 'incorrectWordTranslation'),
				value: 'incorrect-word-translation',
			}, {
				title: i18n('training', 'incorrectSentenceTranslation'),
				value: 'incorrect-sentence-translation',
			}, {
				title: i18n('training', 'incorrectSentence'),
				value: 'incorrect-sentence',
			}, {
				title: i18n('training', 'otherIssues'),
				value: 'other',
			}],
		};
	},

	inject: ['i18n'],

	computed: {
		// for guest mode
		userLoggedIn: function() {
			return this.$store.state.user.user !== null;
		},

		isEnglishWord: function() {
			return this.card?.wordLangSource === 0;
		},

		isChineseWord: function() {
			return this.card?.wordLangSource === 4;
		},

		// markup:
		trainingPageMode: function() {
			if (this.loading) {
				return 'loading';
			}

			const card = this.card;
			if (!card) {
				console.error('Training page doesnt have the card!');
				return 'error';
			}

			if (card.wordIsNew && this.showingNewWord) {
				// todo: replace this.showingNewWord = false on "Next" to card.wordIsUnknown = false
				return 'description';
			}
			
			if (location.href.includes('?only_video')) {
				return 'youglish';
			}

			if (location.href.includes('?only_hanzi')) {
				return 'hanzi';
			}

			// card.repeatForm === 3 || 6 || 8
			if (this.youglishEnabled && [3, 6, 8].includes(card.wordRepeatTime)) {
				// this.cardAnswer.match(/[a-z]/)
				return 'youglish';
			}

			if (this.isChineseWord && Math.random() > 0.7) {
			//	return 'hanzi';
			}

			return 'training';
		},

		// при обновлении - обновляем виджет
		youglishCurrentData: function() {
			if (this.trainingPageMode !== 'youglish') {
				return null;
			}
			const youglishLanguages = {
				0: 'English',
				1: 'Russian',
				4: 'Chinese',
				5: 'French',
				6: 'Spanish',
				7: 'Italian',
				9: 'Portuguese',
				10: 'German',
				12: 'Polish',
				13: 'Japanese',
				14: 'Korean',
				15: 'Swedish',
				16: 'Turkish',
				19: 'Greek',
				20: 'Arabic',
				21: 'Dutch',
				22: 'Hebrew',
				23: 'Thai',
				24: 'Ukrainian'
			};
			// мб для Chinese нужно accent: 'cn'
			if (!youglishLanguages[this.card.wordLangSource]) {
				return null;
			}

			return {
				query: this.youglishQuery,
				language: youglishLanguages[this.card.wordLangSource],
			};
		},

		youglishQuery: function() {
			// пока что не делаем части речи, потому что предлагаем вводить всё равно другую форму
			/*const { wordPartOfSpeech, wordText } = this.card;
			const pov = {
				noun: 'n',
				verb: 'v',
				adjective: 'a',
				adverb: 'ad'
			}[wordPartOfSpeech];
			if (pov) {
				return wordText + ':' + pov;
			} */
			return this.cardAnswer;
		},

		food: function() {
			const i18n = this.i18n;
			const food = [
				[watermelon, i18n('training', 'foodWatermelon')],
				[watermelon, i18n('training', 'foodWatermelon')],
				[watermelon, i18n('training', 'foodWatermelon')],
				[banana, i18n('training', 'foodFood')],
				[carrot, i18n('training', 'foodFood')],
				[chicken, i18n('training', 'foodFood')],
				[chili, i18n('training', 'foodFood')],
				[meat, i18n('training', 'foodFood')],
				[pizza, i18n('training', 'foodPizza')],
				[sushi, i18n('training', 'foodFood')],
				[turkey, i18n('training', 'foodFood')]
			];
			return food[Math.random() * food.length | 0];
		},

		loading: function() {
			return this.$store.state.courseTraining.loading;
		},

		complained: function() {
			return this.$store.state.courseTraining.complained;
		},

		courseIsEmpty: function() {
			const data = this.$store.state.courseTraining.data;
			return !this.loading && data && data.length === 0;
		},

		card: function() {
			const { data, currentCardIndex } = this.$store.state.courseTraining;
			return data[currentCardIndex];
		},

		cardQuestion: function() {
			const { card } = this;
			return card.question.join('');
		},

		currentWordTranscription: function() {
			const parts = (this.card?.wordTranslation || '').split(' | ');
			if (parts.length > 1) {
				return '/' + parts[0] + '/';
			}
			return null;
		},

		currentWordDefinition: function() {
			const parts = (this.card?.wordTranslation || '').split(' | ');
			return parts[parts.length - 1];
		},

		cardAnswerSplit: function() {
			const { card } = this;
			const sentence = card.wholeAnswer;
			const answer = card.answer[1];
			if (answer.includes('*')) {
				// todo: use \p{L} and add u to /regexp/u
				// for unicode words
				const regexp = answer.replace(/[\w\*]+/gi, x => `(${x === '*' ? '\\w+' : x})`);
				const {index, length, ...wordsObject} = sentence.match(regexp);
				const [finalAnswer, ...words] = [].slice.call({...wordsObject, length})
				const pre = sentence.slice(0, index);
				const post = sentence.slice(index + finalAnswer.length);
				const re = answer.split(' ');
				const wordsFields = words.map((word, i) => {
					if (re[i] === '*') {
						return {
							type: 'text',
							text: word
						};
					} else {
						return {
							type: 'input',
							text: '',
							answer: word
						}
					}
				})
				return [{
					type: 'text',
					text: pre
				}, ...wordsFields, {
					type: 'text',
					text: post
				}]
			} else {
				return [{
					type: 'text',
					text: this.cardAnswerPre
				}, {
					type: 'input',
					text: '',
					answer: this.cardAnswer,
				}, {
					type: 'text',
					text: this.cardAnswerPost
				}];
			}
		},

		cardAnswerPre: function() {
			const { card } = this;
			if (this.cardAnswerLanguage === 'zh') {
				try {
					return convertChineseSentenceToPinyin(card.answer[0]);
				} catch (e) {}
			}
			return card.answer[0];
		},

		cardAnswerPost: function() {
			const { card } = this;
			if (this.cardAnswerLanguage === 'zh') {
				try {
					return convertChineseSentenceToPinyin(card.answer[2]);
				} catch (e) {}
			}
			return card.answer[2];
		},

		cardAnswer: function() {
			const { card } = this;
			return card.answer[1];
		},

		wordId: function() {
			return this.card.wordId;
		},

		sentenceId: function() {
			return this.card.sentenceId;
		},

		wordLength: function() {
			const inputWidth = measureTextLength(this.$store.state.courseTraining.answerToSend);
			return Math.max(inputWidth, measureTextLength(this.cardAnswer))	+ 35;
		},

		progress: function() {
			const { data, currentCardIndex } = this.$store.state.courseTraining;
			if (!data) {
				return 0;
			}
			return currentCardIndex / data.length;
		},

		correct: function() {
			let userAnswer = this.$store.state.courseTraining.answerToSend.trim().toLowerCase().split('-').join(' ');
			let cardAnswer = this.cardAnswer.trim().toLowerCase().split('-').join(' ');
			if (this.cardAnswerLanguage === 'gr') {
				userAnswer = polyGreekNormalize(userAnswer);
				cardAnswer = polyGreekNormalize(cardAnswer);
			}
			return userAnswer === cardAnswer;
		},

		cardAnswerLanguage: function() {
			if (this.cardAnswer.match(/[Α-Ωα-ωίϊΐόάέύϋΰήώ]/)) {
				return 'gr';
			}
			if (isChinese(this.cardAnswer)) {
				return 'zh';
			}
			return 'en';
		},

		explainPopupWidth: function() {
			return Math.max(...this.explainOptions.map(option => measureTextLength(option.title)));
		},

		complainPopupWidth: function() {
			return Math.max(...this.reportOptions.map(option => measureTextLength(option.title)));
		}
	},

	mounted: async function() {
		const courseName = this.$route.params.courseName;
		if (courseName === 'ai') {
			const value = window.prompt(
				'Target Language:Native Language; word:translation:part of speech; word:translation:part of speech',
				''
			);
			const [langs, ...rawWords] = value.split(';').map(x => x.trim());
			const [languageTarget, languageNative] = langs.split(':');
			const words = rawWords.map(word => {
				word = word.split(':');
				return {
					word: word[0].trim(),
					translation: word[1].trim(),
					pos: word[2].trim(),
				}
			})

			await this.$store.dispatch('courseTraining/ai-start', {
				languageTarget, languageNative, words
			});
			this.textFieldType = null;
			this.textFieldFocus();
		} else {
			//const user = this.$store.state.user;

			await this.$store.dispatch('courseTraining/start', {
				courseId: courseName,
				userLoggedIn: this.userLoggedIn,
				scenario: this.$route.query.scenario
			});
			this.textFieldType = null;
			this.textFieldFocus();
			this.startCountingSeconds();
		}

		window.addEventListener('keydown', this.checkIfKeyLeave);
		window.addEventListener('click', this.closePopups);
		window.addEventListener('focus', this.onWindowFocus);
		window.addEventListener('blur', this.stopCountingSeconds);
		window.addEventListener('visibilitychange', this.stopCountingSeconds);
		window.addEventListener('beforeunload', this.stopCountingSeconds);
	},

	destroyed: function() {
		window.removeEventListener('keydown', this.checkIfKeyLeave);
		window.removeEventListener('click', this.closePopups);
		window.removeEventListener('focus', this.onWindowFocus);
		window.removeEventListener('blur', this.stopCountingSeconds);
		window.removeEventListener('visibilitychange', this.stopCountingSeconds);
		window.removeEventListener('beforeunload', this.stopCountingSeconds);
		this.stopCountingSeconds();
	},

	methods: {
		textFieldFocus: function() {
			this.$refs.textField?.focus();
		},

		closePopups: function() {
			this.complainMenuOpen = false;
			this.commentsOpen = false;
			this.explainPopupOpen = false;
		},

		next: function(opts) {
			// ignoreCorrect only for HanziWriter
			if (this.correct || (opts && opts.ignoreCorrect)) {
				this.$store.commit('courseTraining/updateWordDate');
				if (this.$store.state.courseTraining.currentCardIndex + 1 === this.$store.state.courseTraining.data.length) {
					this.finishModalOpen = true;
					this.showingNewWord = true;
					const widget = this.$refs.youglishWidget;
					if (widget) {
						widget.pause();
					}
				} else {
					this.$store.commit('courseTraining/nextCard');
					this.textFieldType = null;
					this.showingNewWord = true;
					this.textFieldFocus();
				}
			} else {
				this.textFieldType = 'wrong';
				this.textFieldFocus();
			}
		},

		trainAgain: function() {
			location.reload();
		},

		help: function() {
			this.$store.commit('courseTraining/help');
			this.textFieldFocus();
			//if (this.textFieldType === 'wrong') {
			//	this.textFieldType = 'was-wrong';
			//}
		},

		complain: function(type) {
			let description = type === 'other' ? window.prompt('Описание проблемы:') : '';
			complain({
				sentenceId: this.sentenceId,
				wordId: this.wordId,
				// todo: перенести в конфиг
				type: ['incorrect-word-translation', 'incorrect-sentence-translation', 'incorrect-sentence', 'other'].indexOf(type),
				text: description
			}).then(() => {
				this.complainMenuOpen = false;
				this.$store.commit('courseTraining/complained');
			});
			// complain({
			// 	sentenceId: this.$store.state.courseTraining.data[this.$store.state.courseTraining.currentCardIndex].id,
			// 	wordId: this.card.id,
			// 	complainId: type,
			// 	description,
			// }).then(() => {
			// 	this.complainMenuOpen = false;
			// 	this.$store.commit('courseTraining/complain', {
			// 		wordId: this.card.id,
			// 		type,
			// 		description,
			// 		userId: this.$store.state.user.user.id
			// 	});
			// });
					// <div class="menu-item" @click="complain('incorrect-word-translation')">Неправильный перевод слова</div>
					// <div class="menu-item" @click="complain('incorrect-sentence-translation')">Неправильный перевод предложения</div>
					// <div class="menu-item" @click="complain('incorrect-sentence')">Некорректное предложение</div>
					// <div class="menu-item" @click="complain('other')">Другие проблемы</div>
			// this.$store.commit('courseTraining/complain');
		},

		iKnowIt: function() {
			this.$store.commit('courseTraining/iKnowIt');
			if (this.$store.state.courseTraining.currentCardIndex === this.$store.state.courseTraining.data.length) {
				this.finishModalOpen = true;
				this.showingNewWord = true;
			}
		},

		leave: function() {
			// const watermelon = this.$refs.watermelon;
			// const watermelonRect = watermelon.getBoundingClientRect();
			// const mascotRect = this.$refs.mascot.position;
			// watermelon.animate({
			// 	transform: `translate(${mascotRect.left - watermelonRect.left}px, ${mascotRect.top - watermelonRect.top}px)`,
			// 	opacity: 0.7
			// }, {
			// 	duration: 500
			// });
			setTimeout(() => {
				this.$router.push('/');
			}, 499);
		},

		closeLearning: function() {
			this.showingNewWord = false;
			setTimeout(() => this.textFieldFocus(), 1);
		},

		checkIfKeyLeave: function(event) {
			if (event.key === 'Enter') {
				if (this.finishModalOpen) {
					this.leave();
				} else if (this.showingNewWord) {
					this.closeLearning();
				}
			}
		},

		authGoogle: function() {
			location.href = '/api/auth/google';
		},

		onTextInput: function(event) {
			this.$store.commit('courseTraining/setAnswer', event);
			if (this.textFieldType === 'wrong') {
				this.textFieldType = 'was-wrong';
			}

			if (this.correct && (this.textFieldType === 'wrong' || this.textFieldType === 'was-wrong')) {
				this.textFieldType = 'wow-correct';
			} else if (this.textFieldType === 'wow-correct' && !this.correct) {
				this.textFieldType = null;
			}
		},

		onWindowFocus: function() {
			this.$nextTick(this.textFieldFocus);
			this.startCountingSeconds();
		},

		toggleYouglish: function(value) {
			if (value === undefined) {
				value = !this.youglishEnabled;
			}
			this.youglishEnabled = value;
			window.localStorage.setItem('lango-youglish-enabled', this.youglishEnabled);
		},

		pronounceCurrentSentence: function() {
			const voices = getVoicesForLang('en');
			const voice = voices.goodVoices[0] || voices.otherVoices[0];
			if (!voice) {
				return;
			}
			if (this.correct) {
				pronounce({
					phrase: this.card.wholeAnswer,
					voice
				});
			} else {
				const answer = this.card.answer;
				pronounce({
					phrase: answer[0],
					voice
				}).then(() => {
					if (answer[2].search(/[a-z]/) === -1) {
						return;
					}

					setTimeout(() => {
						pronounce({
							phrase: answer[2],
							voice
						});
					}, answer[1].length * 50);
				})
			}
		},

		pronounceCurrentWord: function() {
			const voices = getVoicesForLang('en');
			const voice = voices.goodVoices[0] || voices.otherVoices[0];
			if (!voice) {
				return;
			}
			pronounce({
				phrase: this.card.wordText,
				voice,
			});
		},

		startCountingSeconds() {
			this.startedTrainAt = Date.now();
		},

		async stopCountingSeconds() {
			if (!this.startedTrainAt) {
				return;
			}

			const secs = Math.round((Date.now() - this.startedTrainAt) / 1000);
			this.startedTrainAt = null;
			if (secs > 0) {
				await addSecondsToLearning(secs);
			}
		},
	},

	watch: {
		youglishCurrentData: function(newVal) {
			this.$nextTick(() => {
				const widget = this.$refs.youglishWidget;
				if (!widget) {
					return;
				}
				if (!newVal) {
					widget.pause();
					return;
				}

				widget.searchAndPlay(newVal.query, newVal.language);
			});
		}
	},

	components: {
		Button,
		ButtonProgress,
		Icon,
		Modal,
		Page,
		PartOfSpeechBadge,
		TextField,
		WordCommentsPopup,
		ModalExplanation,
		YouglishWidget,
		HanziWriter,
		Popup,
		Menu
	},
};
</script>

<style lang="stylus" scoped>
@import "../components/style.styl";

// temporary thing
training-font = Lato, Montserrat, Roboto

.training-page
	text-align center
	// margin-bottom 250px
	// font-family training-font
	// & >>> .training-text-field
	//	font-family training-font !important

.go-back-link
	position absolute
	left 32px
	top 102px
	font-size 14px
	color #656565
	text-decoration none
	height 50vh
	.icon
		padding 6px 4px
		margin-right 6px
		vertical-align middle

@media screen and (max-width: 1100px) {
	.go-back-link {
		display none
	}
}

.word-type-container
	display inline-block
	margin-left 5px
	font-weight 700

.card
	display inline-block
	max-width 800px
	margin-top 67px

@media only screen and (max-width: 699px) {
	.card {
		max-width: 90%;
	}
}

.buttons-panel
	a
		text-decoration none
		font-size 14px
		line-height 20px
		margin-right 15px
		color text-secondary
		white-space nowrap
	a:hover, a:focus, a.active,
	a:hover .icon, a:focus .icon, a.active .icon
		color #777
	a.overlined
		text-decoration line-through
		color #aaa
	.icon
		color text-secondary
		vertical-align middle
		margin-top -2px
		margin-right 6px

.image-container img
	height 100px

.youglish-original-text
	margin-bottom 30px
	margin-top 60px

.button-container.v1-design .button
	width 160px

.button-container.v2-design .button
	width 200px

.finish-modal-content
	padding 40px
	h3
		margin-bottom 10px
	.button-container
		margin-top 10px
		text-align center
		display flex
		flex-direction column
		row-gap 8px

	&.logged
		.image-container 
			text-align center
			height: 105px
		.button
			width 200px

	&.unlogged
		p
			margin 20px 0
		.button
			width 400px

.course-is-empty
	text-align center
	padding-top 40px
	font-style italic

/*
.complain-menu
	background white
	position absolute
	margin-top 10px
	min-width 150px
	padding 5px 0
	text-align right
	border-radius 4px
	box-shadow 0 3px 6px rgba(0, 0, 0, 0.25)
	font-size 14px
	.menu-item
		padding 7px 20px
		cursor pointer
	.menu-item:hover
		background #fafafa
	.menu-item:active
		background #f0f0f0
	&:before
		content ""
		position absolute
		border-color white
		border solid transparent
		border-bottom-color white
		border-width 11px
		margin-left -10px
		top -21px
		left 126px
		z-index 1
		*/

.fade-enter-active, .fade-leave-active {
  transition: .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.progress-bar-container
	position absolute
	top 106px
	width 100%
	text-align center
	.progress-bar
		display inline-block
		width 520px
		max-width calc(100vw - 30px)
		height 12px
		border-radius 12px
		overflow hidden
		background white
		.progress
			background main-color
			height 100%
			transition 0.1s

/* 				<div class="word-definition">
					<b class="notranslate">{{ card.wordText }}</b>
					<div class="word-type-container">
						<PartOfSpeechBadge :content="card.wordPartOfSpeech" />
					</div>
					<div class="word-definition-divider" />
					<div class="transcription">{{currentWordTranscription}}</div>
					<Icon type="sound" />
				</div>
				<div class="word-translation">{{currentWordDefinition}}</div>
*/

.word-definition
	padding-top 92px
	line-height 28px
	font-size 20px
	display flex
	justify-content center
	b
		font-weight 600
	.word-type-container
		display inline-block
		margin-left 12px
		margin-top -2px
		vertical-align middle
		// & >>> span
			// vertical-align unset
			// margin-top -5px
	.word-definition-divider
		background #DCDCDC
		margin 0 20px
		border-radius 2px
		height 28px
		width 2px
		margin-top 2px
		display inline-block
	.transcription
		display inline-block
		color #656565
		font-weight 300
		cursor pointer
	.icon
		padding-left 12px
		color main-color
		cursor pointer
		&:active
			color main-color-darker

.word-translation
	margin-top 32px
	background white
	color #292929
	border-radius 12px
	font-size 16px
	line-height 20px
	padding 16px 20px

.learn-new-word .card
	margin-bottom 64px

.training-container.v1-design
	.translate-text
		margin-top 120px
		font-family: training-font
		font-weight 300
		font-size 20px
		margin-bottom 50px
		line-height: 29px;
		i
			margin-right 5px
	.original-text
		font-family: training-font
		font-size 20px
		margin-bottom 60px
		line-height: 29px;
		.original-text-input
			display inline-block
			& >>> input
				transition 1s
				text-align center
				font-size 20px !important
				font-weight 500
				color #999
	.original-text.wrong >>> input
		color #a00
		background rgba(255, 240, 240, 1)
		transition 0 !important
	.original-text.wow-correct >>> input
		color #0a0
		background rgba(240, 255, 240, 1)
		transition 0 !important

training-block-font()
	font-family main-font
	font-size 16px
	line-height 28px
	color #292929

.training-container.v2-design
	margin-top 121px
	margin-bottom 64px
	text-align left
	&.columns
		display grid
		align-items baseline
		grid-template-columns 1fr 1fr
	&.rows
		text-align center
		display grid
		grid-template-columns 1fr
	.translate-text
		training-block-font()
		padding 24px 32px
		font-weight 500
	.original-text
		training-block-font()
		padding 24px 32px
		font-weight 300
	.original-text-input
		display inline-block
	.original-text >>> input
		training-block-font()
		text-align center
		font-weight 500
	.original-text >>> input:focus
		border-color main-color
		box-shadow inset 0 0 0 0.5px main-color
	.original-text.wrong >>> input
		border-color #E4341E
		box-shadow inset 0 0 0 0.5px #E4341E

@media only screen and (max-width: 700px) {
	.training-container.columns {
		text-align center
		grid-template-columns 1fr !important
	}
}

.explain-link {
	font-size: 36px;
	background-image: linear-gradient(to bottom left, #FF00F5, #0037FA);
	color: transparent; /* Make the text color transparent so the background can be seen */
	background-clip: text;
	-webkit-background-clip: text; /* For Safari compatibility */
	-webkit-text-fill-color: transparent; /* For Safari compatibility */
}

.explain-link .icon
	vertical-align middle
	margin-top -1px
	margin-right 2px
</style>
